import React, { Fragment,useEffect,useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./css/listadoempresa.css";
import * as SecureLS from "secure-ls";
import { useHistory } from "react-router-dom";


function Content() {

  const ls = new SecureLS({encodingType: 'des', isCompression: false, encryptionSecret: 'admin-hyundai'});
  const {infoUsuario} = ls.get('ask-hy');
  const [data] = useState(infoUsuario.data)
  const history = useHistory()
  const [rowsEmpresas, setrowsEmpresas] = useState([])

  const [first, setfirst] = useState(true)

  useEffect(() => {

    const listaEmpresas = (data1) => {
    
      const rows = data1.map((row, index) =>
        
          <Col id={row.EMP_CODIGO} md="4" className="mt-4">
            <Image onClick={() => setEmpresaPre(row)} src={image(row.EMP_CODIGO)} alt="" className="emp-img" fluid />
          </Col>
    
      )
    
      console.log(rows)
    
      setrowsEmpresas(rows)
      setfirst(false)
    
    }

    const image = (idEmpresa) =>{
      try {
        return(
          require('../../images/empresa/'+idEmpresa+'.jpg')
        )
      } catch (error) {
        return(
          ''
        )
      }
    }
    
    const setEmpresaPre = (row) =>{
    
      
      infoUsuario.data.US_EMPRESA_DEFAULT = row
      ls.set("ask-hy", { infoUsuario });
      history.push("/dashboard");
    
    }
   
   

    if(first){
      listaEmpresas(data.US_EMPRESAS)
    }
  
      
    
    

},[data,first,history,infoUsuario,ls]);












  return (
    <Fragment>
      <div className="listado-empresa-cont">
        <Container className="pt-3 pb-5">
          <Row >
            <Col md="12 mt-2 mt-md-3 text-center">
              <h2 className="titulo-seleccione p-2">Seleccione la empresa</h2>
            </Col>
          </Row>
          <Row>
              {rowsEmpresas}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default Content;
